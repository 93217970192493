import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: '',
    component: () => import('../views/dashboard/Index.vue'),
    meta: {
      authRequired: true
    },
    children: [
      {
        name: 'Dashboard',
        path: '',
        component: () => import('../views/dashboard/painel/Index.vue'),
      },
      {
        path: '/aldeias',
        name: 'villages',
        component: () => import('../views/dashboard/villages/List-villages.vue'),
      },
      {
        name: 'users',
        path: '/usuarios',
        component: () => import('../views/dashboard/users/Users-list.vue'),
        meta: {
          owner: true
        },
      },
      {
        path: '/indigenas',
        name: 'indigenous',
        component: () => import('../views/dashboard/indigenous/List-indigenous.vue'),
      },
      {
        path: '/indigenas/lixeira',
        name: 'indigenous-trash',
        component: () => import('../views/dashboard/indigenous/Deleted-indigenous.vue'),
      },
      {
        path: '/etnias',
        name: 'ethnicities',
        component: () => import('../views/dashboard/ethnicities/List-ethnicities.vue'),
      },
    ]
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/auth/Index.vue'),
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/auth/pages/Login.vue')
      },
      {
        name: 'Forgot-password',
        path: '/forgot-password',
        component: () => import('../views/auth/pages/Forgot-password.vue'),
      },
      {
        name: 'Email-confirmation',
        path: '/forgot-password/:token',
        component: () => import('../views/auth/pages/Forgot-password.vue'),
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {

    const isLoggedIn = store.getters.token ? true : false
    
    if (!isLoggedIn) {
      next({ name: 'Login' })
    } 

    const user = store.getters.userInfo

    if (to.matched.some(record => record.meta.owner)) {
      if (isLoggedIn && user.type !== 'ADM') {
        next({ name: 'Dashboard' })
      }
    }

    next() 

  } else {
    next()
  }
})

export default router
