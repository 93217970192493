import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css';
import './assets/css/global.css'
import Notifications from 'vue-notification'
import '@mdi/font/css/materialdesignicons.css'
import avatarInitialsName from '@/filters/avatarInitialsName';

Vue.config.productionTip = false

Vue.use(Notifications);
Vue.filter('avatarInitialsName', avatarInitialsName);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
