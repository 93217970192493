// initial state
const state = () => ({
  drawer: false
});

//getters
const getters = {
  drawer: (state: any) => {
    return state.drawer;
  }
}

// mutations
const mutations = { 
  setDrawer(state: any, drawer: boolean){
    state.drawer = drawer
  },
  changedDrawer(state:any) {
    state.drawer = !state.drawer
  }
}

export default {
  state,
  getters,
  mutations
}