import User from "@/models/user";

// initial state
const state = () => ({
  info: {id: 0, name: '', email: ''} as User,
  token: null,
  alertConfirmEmail: false
});

//getters
const getters = {
  token: (state: any) => {
    return state.token;
  },
  userInfo: (state: any) => {
    return state.info;
  },
  alertConfirmEmail: (state: any) => {
    return state.alertConfirmEmail
  }
}

// mutations
const mutations = { 
  setInfo(state: any, user: User){
    state.info = user
  },

  setToken(state: any, token: string){
    state.token = token;
  },

  setStatusModalConfirmEmail(state: any, status: boolean){
    state.alertConfirmEmail = status;
  }
}

export default {
  state,
  getters,
  mutations
}