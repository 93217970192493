export default function (value: string): string {
  const words = value.split(' ');
  const length = words.length;
  
  if (length == 1) {
    return words[0].substring(0, 2).toUpperCase();
  }

  const word = words[0].substring(0, 1) + words[length - 1].substring(0, 1);

  return word.toUpperCase();
} 