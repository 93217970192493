import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import Cookies from 'js-cookie';
import user from './modules/user'
import menu from './modules/menu'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

//Store user in cookies
const plugins = [createPersistedState({
  paths: ['user'],
  storage: {
    getItem: key => Cookies.get(key),
    setItem: (key, value) => Cookies.set(key, value, { expires: 1, secure: true }),
    removeItem: key => Cookies.remove(key)
  }
})]

export default new Vuex.Store({
  modules: {
    user,
    menu
  },
  plugins: plugins,
  strict: debug

})
